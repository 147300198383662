import ImgTag from "@/components/Helpers/ImgTag";
import Link from "next/link";
import { useRouter } from "next/router";

export default function NotFoundPage() {
  const { locale } = useRouter();

  return (
    <section className="min-h-[70vh] my-5 flex flex-col justify-center items-center bg-white rounded-md">
      <ImgTag
        // src="/svgs/page-not-found.svg"
        src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=240&h=240,fit=contain,format=auto,quality=75/page-not-found.svg`}
        className="object-fill h-60 w-60"
        height={400}
        width={400}
        alt="Not Found Img!"
      />
      <h1 className="text-[1.5rem] pt-1.5">
        {locale == "ar"
          ? "عفوًا، الصفحة غير موجودة!"
          : "Sorry; this page not found!"}
      </h1>
      <Link
        href={"/"}
        className="mt-[1rem] text-[1.2rem] min-w-[280px] min-h-[60px] text-[white] font-[600] bg-[#D9232E] rounded-[4px] border-[1px] border-[#D9232E] hover:bg-[white] hover:text-[#D9232E] duration-150 flex justify-center items-center"
      >
        {locale == "ar" ? "متابعة التسوق" : "Continue shopping"}
      </Link>
    </section>
  );
}
